<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <iframe
            v-if="content && content.display_type === 'iframe'"
            ref="reswindow"
            class="w-full h-[82vh]"
            :src="content.locale[propertyHub.currentLanguage].iframe_url"
        ></iframe>

        <div v-if="content && content.display_type === 'page'">
            <hero-image
                :image="
                    content.locale[propertyHub.currentLanguage].image ||
                    '/imgs/property-hub/beach-chairs.png'
                "
            ></hero-image>

            <div class="pt-4 px-5 pb-0 text-gray-700 space-y-6">
                <div>
                    <div
                        class="font-bold text-lg mb-1"
                        :style="{ color: propertyHub.site.colors.primary }"
                    >
                        {{ content.locale[propertyHub.currentLanguage].title }}
                    </div>
                </div>
                <div>
                    <div
                        class="mb-1 text-gray-700 prose mdown"
                        v-html="
                            markdown(
                                content.locale[propertyHub.currentLanguage]
                                    .content
                            )
                        "
                    ></div>
                </div>
            </div>

            <div
                v-if="
                    content.locale[propertyHub.currentLanguage].links.length > 0
                "
                class="mt-4 flex justify-start items-center space-x-3 snap-x px-5 overflow-x-scroll scrollbar-hide md:scrollbar-default"
            >
                <button
                    v-for="(link, key) in content.locale[
                        propertyHub.currentLanguage
                    ].links"
                    :key="key"
                    class="text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                    :style="{
                        'background-color': propertyHub.site.colors.primary,
                    }"
                    @click="clickedLink(link)"
                >
                    {{ link.linktext }}
                </button>
            </div>

            <div
                class="my-6"
                v-for="(richlink, lkey) in content.locale[
                    propertyHub.currentLanguage
                ].rich_links"
                :key="lkey"
            >
                <img class="w-full" :src="richlink.image" />
                <div class="p-6">
                    <p class="text-sm">
                        <span class="underline text-lg my-2">{{
                            richlink.title
                        }}</span>
                        <br />
                        {{ richlink.description }}
                    </p>
                    <button
                        class="text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                        :style="{
                            'background-color': propertyHub.site.colors.primary,
                        }"
                        @click="clickedLink(richlink)"
                    >
                        {{ richlink.linktext }}
                    </button>
                </div>
            </div>

            <div
                v-if="
                    content.locale[propertyHub.currentLanguage].gallery.length
                "
                class="mt-4"
            >
                <div
                    v-for="(image, id) in content.locale[
                        propertyHub.currentLanguage
                    ].gallery"
                    :key="id"
                >
                    <img
                        v-devise-image="{ image: image }"
                        class="w-full mt-5"
                    />
                </div>
            </div>
        </div>
        <div v-if="!content && !loading">
            <div
                class="text-center px-8 mt-8"
                :style="{ color: propertyHub.site.colors.primary }"
            >
                <div class="text-xl font-extrabold">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'unable-to-find-resource'
                        ]
                    }}
                </div>
                <div class="mt-4">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'click-back-arrow-ins'
                        ]
                    }}
                </div>
            </div>
        </div>

        <!-- Showing wellness events for the wellness content section -->
        <EventsNavigator
            v-if="
                content &&
                !loading &&
                slugApprovedForEvents
            "
            starting-filter="wellness"
            :show-filters="false"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

const MarkdownIt = require('markdown-it');
const md = new MarkdownIt().disable(['link', 'image']);

export default {
    name: 'ContentView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        EventsNavigator: () => import('./components/EventsNavigator.vue'),
    },

    data() {
        return {
            loading: true,
            content: null,
        };
    },

    watch: {
        $route: 'onRouteChange',
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),

        ...mapGetters(['getContentFromSlug', 'getContentFromId']),

        slugApprovedForEvents()
        {
            const slug = this.content.locale[this.propertyHub.currentLanguage].slug;
            const approvedSlugs = [
                'en-360-wellnez',
                'es-360-wellnez',
                'en-health-wellness',
                'es-health-wellness',
                'en-fitness-land-sports',
                'es-fitness-land-sports'
            ];

            return approvedSlugs.includes(slug);
        }
    },

    mounted() {
        this.requestRetrieveContent();
    },

    methods: {
        ...mapActions(['getContent', 'sendPageView']),

        requestRetrieveContent() {
            this.loading = true;
            this.getContent()
                .then(() => {
                    this.content = this.getContentFromSlug(
                        this.$route.params.slug
                    );
                    this.setTitleAndSendView();
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onRouteChange() {
            this.content = this.getContentFromSlug(this.$route.params.slug);
            this.setTitleAndSendView();
        },

        setTitleAndSendView() {
            document.title =
                this.propertyHub.site.name +
                ' ' +
                this.content.locale[this.propertyHub.currentLanguage].title;
            this.sendPageView(
                this.propertyHub.site.name +
                    ' ' +
                    this.content.locale[this.propertyHub.currentLanguage].title
            );
        },

        clickedLink(link) {
            if (link.type === 'Playa Safe Stay') {
                return this.$router.push('/playa-safe-stay');
            }

            if (link.type === 'Make Reservation' || link.type === 'Link Off') {
                const firstChar = Array.from(link.linkurl)[0];
                if (firstChar === '/') {
                    // if the url starts with / then it's internal
                    return this.$router.push(link.linkurl);
                } else {
                    return window.open(link.linkurl, '_blank');
                }
            }
            if (link.type === 'Click To Call') {
                return window.open(`tel:${link.linkurl}`, '_blank');
            }
            if (link.type === 'Download File') {
                const url = window.location.pathname;
                const filename = url.substring(url.lastIndexOf('/') + 1);
                return this.downloadFile(link.linkurl, filename);
            }
            if (link.type === 'Content') {
                const content = this.getContentFromId(link.pageId);
                if (
                    content &&
                    content.locale &&
                    content.locale[this.propertyHub.currentLanguage]
                ) {
                    return this.$router.push(
                        `/property-hub/info/${
                            content.locale[this.propertyHub.currentLanguage]
                                .slug
                        }`
                    );
                }
            }
            if (link.linkurl) {
                return this.$router.push(link.linkurl);
            }
        },

        downloadFile(dataUrl, filename) {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = filename;
            link.click();
        },
        markdown(str) {
            return md.render(str);
        },
    },
};
</script>
